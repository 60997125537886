class carComponent extends Component {

    static name() {
        return "carComponent";
    }

    static componentName() {
        return "carComponent";
    }

    getProps() {
        return  ['is_side_nav'];
    }
    getComputed() {
        return {
            status(){
                return this.$store.getters.getCarStatus
            },
            itemsSelected:function(){
                return this.$store.getters.getItemCar;
            },
            totalAmount(){
                return this.$store.getters.getCarAmount.toFixed(2)
            },
            checkoutEnabled(){
                if(this.$store.getters.getSession==null)
                    return false;
                return true;
            },
        };
    }

    getMethods() {
        return {
            itemQtyIncrement:this.itemQtyIncrement,
            itemQtyDecrement:this.itemQtyDecrement,
            deleteItemFromCar:this.deleteItemFromCar,
            showItemPrice: this.showItemPrice,
            showPriceLabel: this.showPriceLabel
        };
    }

    itemQtyIncrement(itemCode){
        this.$store.dispatch('changeCartQty',{itemCode:itemCode.id,action:'up'});
    }

    itemQtyDecrement(itemCode){
        this.$store.dispatch('changeCartQty',{itemCode:itemCode.id,action:'down'});
    }

    deleteItemFromCar(itemCode){
        this.$store.dispatch('changeCartQty',{itemCode:itemCode.id,action:'delete'});
    }

    showItemPrice(item){
        let price = item.Price;
        if(this.$store.getters.getPriceToShow)
            price =item.RowTotal;
        else{
            if(item.RowNet)
                price =item.RowNet
        }
        return parseFloat(Math.round(price * 100) / 100).toFixed(2);
    }

    showPriceLabel() {
        if(this.$store.getters.getPriceToShow)
            return '(IVA INC)';
        else{
            return '(IVA EXC)';
        }
    }

    getTemplate() {
        return `<div class="card">
                 <div class="card-content">
                      <div class="card-panel cart-items-section row">
                            <div class-="col m12">
                                <span class="btn clear-basket col m12 s12" @click="$store.dispatch('clearItemToCar','view')">{{tr("Clear Basket")}}</span>
                           </div>
                            <template  v-for="item in itemsSelected">
                                <div class="row produktliste cart-items-list" :title="'Precio Unitario '+parseFloat(Math.round(item.item.Price * 100) / 100).toFixed(2)">
                                    <div class="col m12 s12 item-title">
                                        <span class="truncate" :title="item.item.Name">{{item.item.Name}}</span>
                                    </div>
                                    <div class="col m12 s12 item-qty-section">
                                       <div class="row carControl">		    
                                            <span @click="itemQtyIncrement(item)" class="basket-icons col s2"><i class="fas fa-angle-up waves-effect" ></i></span>
                                            <input class="input-item-amount col s5" type="text" readonly :value="item.cant" placeholder="0" >
                                            <span @click="itemQtyDecrement(item)" class="basket-icons col s2"><i class="fas fa-angle-down waves-effect" ></i></span>
                                            <span @click="deleteItemFromCar(item)" class="col s1"><i class="far fa-trash-alt" ></i></span>
                                        </div>                                              
                                    </div>
                                </div>
                            </template>
                            <div class="row produktliste total-amount">
                               <div class="col s12">
                                    <strong>TOTAL {{showPriceLabel()}}</strong>
                               </div>
                               <div class="col s12 right-align">
                                    <strong>$ {{totalAmount}} </strong>
                                </div>
                            </div>
                            <div class="row produktliste" v-if="checkoutEnabled">
                                <div>
                                    <a class="btn checkout-btn col m12 s12" @click="$router.push('/badges');">{{tr("Checkout")}}</a>
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>`;
    }
}

carComponent.registerComponent();